import React, {useEffect} from 'react'


export default function Default() {

    useEffect(()=>{
        let token = localStorage.getItem('token')
        if(token === undefined || token === null ){
        window.location='/login'
        }

        else{window.location='/dashboard'}

    },[])


    return (
        <div>
            
        </div>
    )
}
