import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Navigation from '../Components/Navigation'
import TheoryList from '../Components/Subpages/SubpagesLists/TheoryList'
import TestsList from '../Components/Subpages/SubpagesLists/TestsList'
import TaskList from '../Components/Subpages/SubpagesLists/TaskList'
import '../Appp.css'

const axios = require('axios')

export default function BlockId() {

    const [rowName, setRowName] = useState('Загрузка...')

    const [blockId, setBlockId] = useState()
    const [selectedSubpage, setSelectedSubpage ] = useState('Theory')


    let parametr = useParams()


    const getId = () =>{
        let id = parametr.block_id
        return id
    }
    
    useEffect(() => {
        let blockId = getId()
        loadRows(blockId)
        setBlockId(blockId)
        //eslint-disable-next-line
    }, [])


    const loadRows = async( block_id ) => {
        let data = {}
        data.row_id = block_id
        console.log(data)
        let answer = await axios.post('/api/chapters/info', data);
        console.log(answer)

        let block_name = answer.data.rowName
        setRowName(block_name)

    }
 
    const subpageHandler = ( event ) => {
        
        event.preventDefault();
        let switchToPage = event.target.dataset.page
        setSelectedSubpage(switchToPage)
        
    }


    return (
        <div className='dashboard_container'>
            <Navigation/>
            <div className='dashboard_content'>
            <div className='blockid_rowname'>{rowName}</div>
            <div className='containes'>
                <button className='blocks_button' data-page='Theory' onClick={subpageHandler}>Теория</button>
                <button className='blocks_button' data-page='Tests' onClick={subpageHandler}>Тесты</button>
                <button className='blocks_button' data-page='Tasks' onClick={subpageHandler}>Задачи</button>    
            </div>
            <div className='subpage_content'>

                { selectedSubpage === 'Theory' ? <TheoryList block_id={blockId} /> : <></>}
                
                { selectedSubpage === 'Tests' ? <TestsList block_id={blockId} /> : <></>}

                { selectedSubpage === 'Tasks' ? <TaskList block_id={blockId} /> : <></>}

            </div>

            </div>
        </div>
    )
}
