import React, { useState, useEffect } from 'react'

const axios = require('axios')

export default function DashbEditOverlay({ selectedId, closeOverlay, reload }) {


    const [ blockName, setBlockName] = useState('')
    const [ blockColor, setBlockColor ] = useState('')

    
    const getInfo = async (selectedId) => {
        let form ={ }
        form.block_id = selectedId
        let request = await axios.post('/api/blocks/getOne', form)
        console.log(request)

        let name = request.data.block.name
        let color = request.data.block.color
        setBlockName(name)
        setBlockColor(color)
    }

    useEffect(()=>{
        getInfo(selectedId)
    }, [selectedId])

    const updateBlock = async (event) => {
        event.preventDefault();
        console.log('updateBlock init')
        let form = { blockName, blockColor }
        form.block_id = selectedId
        let request = await axios.post('/api/blocks/update', form)
        console.log(request)
        if(request.data.ok === true){
            alert('OK')
            getInfo(selectedId)
            reload()
        }
    }

    const deleteBlock = async () =>{
        let form = {}
        form.block_id = selectedId
        let request = await axios.post('/api/blocks/delete', form)
        if(request.data.ok === true){
            closeOverlay()
            reload()
        }

    }


    return (
            <div className='overlay'>
                <div className='overlay_content'>
                    <h3>Редактировать блок ({selectedId})</h3>
                    <form onSubmit={updateBlock}>
                        <input className='auth_form' required type='text' value={blockName} placeholder='Название' onChange={e => setBlockName(e.target.value)}></input>
                        <input className='auth_form' required type='text' value={blockColor} placeholder='Цвет' onChange={e => setBlockColor(e.target.value)}></input>
                        <button className='overlay_button' type="submit">Сохранить</button>
                    </form>
                    <button className='overlay_button' onClick={deleteBlock}>Удалить</button>
                    <button className='overlay_button' onClick={closeOverlay}>Закрыть</button>
                </div>
            </div>
    )
}
