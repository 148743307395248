import React, { useState, useEffect } from 'react'
import pageUp from '../../Images/pageUp.png'
import pageDown from '../../Images/pageDown.png'
import TaskOverlay from '../../Overlays/TaskOverlay'
import { useParams } from 'react-router-dom'


const axios = require('axios')

export default function TaskList({ block_id = undefined }) {

    const [showOverlay, setOverlay] = useState(false)
    const [ listOfTests, setTestsLists ] = useState([])

    const params = useParams()

    const openOverlay = (event) => {
        event.preventDefault();
        setOverlay(true)
    }
    const closeOverlay = () => {
        setOverlay(false)
    }

    useEffect(()=>{
        if(block_id === undefined){
            return
        }
        loadRows(block_id)
    }, [block_id])

    const reload = async () => {
        loadRows(block_id)
    }

    const loadRows = async( block_id ) => {
    
        let data = {}
        data.block_id = block_id
        console.log(data)
        let answer = await axios.post('/api/tasks/list', data);
        console.log(answer)

        let block_row = answer.data.tasks

        setTestsLists(block_row)
    
    }

    const moveToTest = (event) => {
        event.preventDefault();
        let id = event.currentTarget.dataset.id
        console.log(event.target)
        if(event.target.className.includes('pagebutton')){
            return
        }
        if(event.target.className.includes('icon')){
            return
        }
        sessionStorage.setItem('lastPage', `/blocks/${params.id}/${params.block_id}`)

        window.location = '/task/'+id
        
    }

    const orderUp = async (event) => {
        event.preventDefault();
        let id = event.currentTarget.dataset.id
        let data = { id: id }
        let answer = await axios.post('/api/tasks/up', data);
        console.log(answer)
        loadRows( block_id )
    }

    const orderDown = async (event) => {
        event.preventDefault();
        let id = event.currentTarget.dataset.id
        let data = { id: id }
        let answer = await axios.post('/api/tasks/down', data);
        console.log(answer)
        loadRows( block_id )
    }


    const deleteBlock = async (event) => {

        event.preventDefault();
        let id = event.currentTarget.dataset.id
        let data = { id: id }
        let answer = await axios.post('/api/tasks/delete', data);
        if(answer.data.ok){
            alert('Успешно удалено')
        }
        loadRows( block_id )
    } 

    return (
        <div className='utils-mt'>
            <div className='subpage_header_container'>
            Список задач <button onClick={openOverlay}>Создать новую задачу</button>
            </div>
            <div>
                { listOfTests.map( test => {

                    return (
                            <div className='test-row' data-id={test.id} onClick={moveToTest}>
                                <div className='test-nav'>
                                    <button className='pagebutton' onClick={orderUp} data-id={test.id}><img className='icon' src={pageUp} alt='pageup'/></button>
                                    <button className='pagebutton' onClick={orderDown} data-id={test.id}><img className='icon' src={pageDown} alt='pagedown' /></button>
                                    <button className='pagebutton' onClick={deleteBlock} data-id={test.id}>Удалить</button>
                                </div>
                                <div className='test-question'>
                                    { test.name }
                                </div>
                            </div>
                    )
                })}
            </div>
            <TaskOverlay showOverlay={showOverlay} reload={reload} block_id={block_id} closeOverlay={closeOverlay}/>
        </div>
    )
}


