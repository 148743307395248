import React, { useState, useEffect } from 'react'
import BlockIdOverlay from '../../Overlays/BlockIdOverlay'
import edit from '../../Images/edit.png'
import pageUp from '../../Images/pageUp.png'
import pageDown from '../../Images/pageDown.png'
import TheoryEditOverlay from '../../Overlays/TheoryEditOverlay'


const axios = require('axios')

export default function TheoryList({ block_id = undefined }) {

    const [showOverlay, setOverlay] = useState(false)
    const [listOfChapters, setChaptersList ] = useState([])
    const [selectedId, setSelectedId] = useState('')
    const [ isEditOverlay, setEditOverlay ] = useState('')

    const openOverlay = (event) => {
        event.preventDefault();
        setOverlay(true)
    }
    const closeOverlay = () => {
        setOverlay(false)
    }

    useEffect(()=>{
        if(block_id === undefined){
            return
        }
        loadRows(block_id)
    }, [block_id])

    const reload = async () => {
        loadRows(block_id)
    }

    const loadRows = async( block_id ) => {
    
        let data = {}
        data.row_id = block_id
        console.log(data)
        let answer = await axios.post('/api/chapters/list', data);
        console.log(answer)

        let block_row = answer.data.chapters

        setChaptersList(block_row)
        console.log(block_row)
    
    }


    const closeEditOverlay = (event) => {
        event.preventDefault();
        setEditOverlay(false)
    }
    const moveToChapter = (event) => {
        event.preventDefault();
        let id = event.currentTarget.dataset.id
        window.location = '/theory/'+id
        
    }
    const openEditOverlay = (event) => {
        event.preventDefault()
        let id = event.currentTarget.dataset.id
        setSelectedId(id)
        setEditOverlay(true)
    }

    const orderUp = async (event) => {
        event.preventDefault();
        let id = event.currentTarget.dataset.id
        let data = { id: id }
        let answer = await axios.post('/api/chapters/up', data);
        console.log(answer)
        loadRows( block_id )
    }

    const orderDown = async (event) => {
        event.preventDefault();
        let id = event.currentTarget.dataset.id
        let data = { id: id }
        let answer = await axios.post('/api/chapters/down', data);
        console.log(answer)
        loadRows( block_id )
    }

    return (
        <div className='utils-mt'>
            <div className='subpage_header_container'>
            Список теории <button onClick={openOverlay}>Создать новую главу теории</button>
            </div>
            <div>
                {listOfChapters.map( theory => {

                    return (
                        <div className='chapter-row'>
                            
                        {/* // <a className='table_row_a' href={'/theory/'+theory.id}> */}
                        <div data-id={theory.id} onClick={moveToChapter} style={{backgroundImage: `url(${theory.media_url})`}} className='subpage_chapter'>
                            <div className='subpage_chapter_content'>{theory.name}</div>
                        </div>
                        <div className='subpage_chapter_sidemenu'>
                            <button className='pagebutton' onClick={orderUp} data-id={theory.id}><img className='icon' src={pageUp} alt='pageup'/></button>
                            <img alt='edit' className='icon pagebutton' data-id={theory.id}  onClick={openEditOverlay} src={edit}></img>
                            <button className='pagebutton' onClick={orderDown} data-id={theory.id}><img className='icon' src={pageDown} alt='pagedown' /></button>
                        </div>
                        {/* // </a> */}
                        </div>
                    )
                })}
            </div>
            <BlockIdOverlay showOverlay={showOverlay} reload={reload} block_id={block_id} closeOverlay={closeOverlay}/>
            { isEditOverlay ? <TheoryEditOverlay selectedId={selectedId} closeOverlay={closeEditOverlay} reload={reload} /> : <></>}
        </div>
    )
}


