import React from 'react'



export default function Navigation() {
    const logOut = () => {
        localStorage.removeItem('token')
        window.location='/login'
    }

    return (
        <div className='dashboard_nav'>
                <div className='nav_content'>
                    <a href='/dashboard'>Содержание</a>
                    <div>Пользователи</div>
                    <div>Статистика</div>
                </div>
                <div className='nav_logout'>
                    <div onClick={logOut}>Выйти</div>
                </div>
            </div>
    )
}
