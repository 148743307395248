import React, { useState } from 'react'


const axios = require('axios')
export default function TestOverlay({ showOverlay, block_id, closeOverlay, reload }) {

    const [ testQuestion , setTestQuestion ] = useState('')
    const [ taskType , setTaskType ] = useState(0)

    const submitOverlay = async (event) => {
        event.preventDefault();

        let form = {}
        form.name = testQuestion
        form.type = parseInt(taskType)
        form.block_id = block_id
        if(block_id === undefined){
            return alert('BLOCK_ID IS REQUIRED TO CREATE NEW CHAPTER')
        }

        let req = await axios.post('/api/tasks/', form)
        console.log(req)
        if(req.data.ok === true){
            alert('OK')
            closeOverlay()
            setTestQuestion('')
            reload()
        }
    }
    return (
        <>
            {
                showOverlay ? 
                (<div className='overlay'>
                    <div className='overlay_content'>
                        <form onSubmit={submitOverlay}>
                            <input className='auth_form' required type='text' value={testQuestion} placeholder='Введите вопрос задачи' onChange={e => setTestQuestion(e.target.value)}></input>

                            <select className='auth_form' required type='text' value={taskType} onChange={e => setTaskType(e.target.value)}>
                                <option value='0'>
                                    Задача с пропущенным словом
                                </option>
                                <option value='1'>
                                    Вопрос - ответ впечатать
                                </option>
                                <option value='2'>
                                    Вопрос -  2 ответа впечатать
                                </option>
                                <option value='3'>
                                    Схема (P/G/F1)
                                </option>
                            </select>
                            <button className='overlay_button' type="submit">Добавить</button>
                        </form>
                        <button className='overlay_button' onClick={closeOverlay}>Закрыть</button>
                    </div>
                </div>) : 
                <></> 
            }
        </>
    )
}
