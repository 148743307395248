import React from 'react'

export default function TableHead({ list }) {
    
    return (
        <div className='table_grid'>
            {
                list.map( item => {
                    return <div className='table_grid_item'>{item}</div>
                })
            }
        </div>
    )
}
