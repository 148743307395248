import React, { useState } from 'react'


const axios = require('axios')
export default function TestOverlay({ showOverlay, block_id, closeOverlay, reload }) {

    const [ testQuestion , setTestQuestion ] = useState('')

    const submitOverlay = async (event) => {
        event.preventDefault();

        let form = {}
        form.question = testQuestion
        form.block_id = block_id
        if(block_id === undefined){
            return alert('BLOCK_ID IS REQUIRED TO CREATE NEW CHAPTER')
        }

        let req = await axios.post('/api/tests/', form)
        console.log(req)
        if(req.data.ok === true){
            alert('OK')
            closeOverlay()
            setTestQuestion('')
            reload()
        }
    }
    return (
        <>
            {
                showOverlay ? 
                (<div className='overlay'>
                    <div className='overlay_content'>
                        <form onSubmit={submitOverlay}>
                            <input className='auth_form' required type='text' value={testQuestion} placeholder='Введите вопрос теста' onChange={e => setTestQuestion(e.target.value)}></input>
                            <button className='overlay_button' type="submit">Добавить</button>
                        </form>
                        <button className='overlay_button' onClick={closeOverlay}>Закрыть</button>
                    </div>
                </div>) : 
                <></> 
            }
        </>
    )
}
