import React, { useState, useEffect } from 'react'

const axios = require('axios')

export default function TheoryEditOverlay({ selectedId, closeOverlay, reload }) {


    const [ blockName, setBlockName] = useState('')
    const [ isUplaoding, setUploading] = useState(false)
    const [ blockURL, setBlockURL ] = useState('')
    const [ uploadingMessage, setUploadingMessage ] = useState('')

    // const [ blockColor, setBlockColor ] = useState('')

    
    const getInfo = async (selectedId) => {
        console.log('a')
        let form ={ }
        form.id = selectedId
        console.log( selectedId)
        let request = await axios.post('/api/chapters/getOne', form)
        console.log(request)

        let name = request.data.block.name
        let url = request.data.block.media_url

        setBlockName(name)
        setBlockURL(url)
    }

    useEffect(()=>{
        getInfo(selectedId)
    }, [selectedId])

    const updateBlock = async (event) => {
        event.preventDefault();
        console.log('updateBlock init')
        let form = {}
        
        form.id = selectedId
        form.name = blockName

        let request = await axios.post('/api/chapters/update', form)
        console.log(request)
        if(request.data.ok === true){
            alert('OK')
            getInfo(selectedId)
            reload()
        }
    }

    const deleteBlock = async () =>{
        let form = {}
        form.id = selectedId
        let request = await axios.post('/api/chapters/delete', form)
        if(request.data.ok === true){
            closeOverlay()
            reload()
        }

    }

    const handleIconUpload = async (event) => {
        let icon = event.target.files[0]
        if(icon === null || icon === undefined){
            return alert('Ошибка')
        }

        setUploading(true)
        setUploadingMessage('Загрузка файла на сервер...')

        let formData = new FormData()
        formData.append('file', icon);
        
        const req_config = {
            headers: {
              "content-type": "multipart/form-data"
            }
          };
        let sendFile = await axios.post('/api/media/', formData, req_config)
        console.log(sendFile)
        if(!sendFile.data.ok){
            setUploading(false)
            setUploadingMessage('Ошибка загрузки' + sendFile.data.e)
            return
        }
        
        let { url } = sendFile.data


        let updateData = {
            id: selectedId,
            url: url
        }
        let updateIcon = await axios.post('/api/chapters/media', updateData)

        if(!updateIcon.data.ok){
            setUploading(false)
            setUploadingMessage('Файл был загружен, но произошла ошибка обновления контента')
        }

        if(updateIcon.data.ok){
            setUploading(false)
            getInfo(selectedId)
            alert('Файл успешно загружен')
        }

    }


    return (
            <div className='overlay'>
                <div className='overlay_content'>
                    <h3>Редактировать главу ({selectedId})</h3>
                    <form onSubmit={updateBlock}>
                        <input className='auth_form' required type='text' value={blockName} placeholder='Название' onChange={e => setBlockName(e.target.value)}></input>
                        Обложка
                        { blockURL.length > 0 ? <img alt={blockName} className='block-edit-bg' src={`${blockURL}`}/> : <></>}
                        { isUplaoding ? <div className='auth_form'>{uploadingMessage}</div> : <input className='auth_form' type='file' onChange={handleIconUpload}/> }
                        
                        <button className='overlay_button' type="submit">Сохранить</button>
                    </form>
                    <button className='overlay_button' onClick={deleteBlock}>Удалить</button>
                    <button className='overlay_button' onClick={closeOverlay}>Закрыть</button>
                </div>
            </div>
    )
}
