import axios from 'axios'
import React,   { useState,  useEffect } from 'react'


import { useParams } from 'react-router-dom'
import './editor.css'

export default function Theory() {

    const [content, setContent] = useState([])
    const [name, setName] = useState('Загрузка')



    useEffect(() => {
        load()
        // eslint-disable-next-line
    }, [])

    


    const params = useParams()

    const load = async () => {
        let id = params.id
        let data = { id: id }

        let request = await axios.post('/api/chapters/getContent', data)
        console.log(request)
        let c = request.data.content
        setName(c.name)
        setContent(c.content)
        
    }

    const syncContent = async (c) => {
        let id = params.id
        let data = { id: id, content: c}
        console.log(data)
        let request = await axios.post('/api/chapters/content', data)
        console.log(request)
    }

    const saveContent = () => {

        console.log('saving')

        let cE = document.getElementsByClassName('contentEditable')
        let cC = [...content]

        let c = getContent(cE, cC)
        

        syncContent(c)
        setContent(c)
        

    }
    const getContent = ( cE, cC ) => {
        try{
            for(let i = 0; i < cE.length; i++){
                let blockType = cE[i].dataset
                switch(blockType.type){
                    
                    case('text'): {
                        cC[i].value = cE[i].innerText
                        break
                    }
                    case('image'): {
                        let val = blockType.value
                        if(val === undefined){
                            cC[i].value = ''
                            break
                        }
                        cC[i].value = val
                        break
                    }
                    default:{
                        // console.log(blockType)
                    }
                }
            }
        }catch(e){
            console.log(e)
        }
        console.log('get', cC)
        return cC
        
    }

    const setContentValue = ( idx, value ) => {
        let cC = [...content]
        console.log(idx)
        idx = parseInt(idx)

        // console.log(cC)
        // console.log(idx, value)
        cC[idx].value = value
        setContent(cC)

        saveContent()

    }
    

    const addTextBlock = () => {

        saveContent()

        let newObject = {}
        newObject.type='text'
        newObject.value=''
        let currentContent = [...content, newObject]
        setContent(currentContent)

        // forceUpdate()

    }

    // const addHeaderBlock = () => {

    //     let newObject = {}
    //     newObject.type='header'
    //     newObject.value=''
    //     let currentContent = [...content, newObject]
    //     setContent(currentContent)

    //     // forceUpdate()

    // }
    const addImageBlock = () => {
        let newObject = {}
        newObject.type='image'
        newObject.value=''
        let currentContent = [...content, newObject]
        setContent(currentContent)

        // forceUpdate()

    }
    // const mutateBold = () => {
    //     document.execCommand('bold')
    // }

    const moveUp = (event) => {

        saveContent()
        let idx = event.currentTarget.dataset.idx
        idx = parseInt(idx)
        
        if(idx === 0){
            return
        }

        let currentContent = content
        let moveDown = currentContent[idx - 1]
        let moveUp = currentContent[idx]

        currentContent[idx - 1] = moveUp
        currentContent[idx] = moveDown

        setContent([...currentContent])


    }

    const moveDown = (event) => {
        
        saveContent()
        let idx = event.currentTarget.dataset.idx
        idx = parseInt(idx)

        let currentContent = content
        let moveDown = currentContent[idx]
        let moveUp = currentContent[idx + 1]

        currentContent[idx] = moveUp
        currentContent[idx + 1] = moveDown
        console.log(currentContent)
        setContent([...currentContent])
        
    }

    const uploadImage = async (event) => {

        saveContent()

        let idx = event.currentTarget.dataset.idx
        let icon = event.target.files[0]
        if(icon === null || icon === undefined){
            return alert('Ошибка')
        }

        // setUploading(true)
        // setUploadingMessage('Загрузка файла на сервер...')

        let formData = new FormData()
        formData.append('file', icon);
        
        const req_config = {
            headers: {
              "content-type": "multipart/form-data"
            }
          };
        let sendFile = await axios.post('/api/media/', formData, req_config)
        console.log(sendFile)
        if(!sendFile.data.ok){
            // setUploading(false)
            // setUploadingMessage('Ошибка загрузки' + sendFile.data.e)
            return
        }
        
        let { url } = sendFile.data

        setContentValue(idx, url)

    }
    const deleteBlock = (event )=>{
        let idx = event.currentTarget.dataset.idx
        idx = parseInt(idx)
        let cC = [...content]
        let nC = []
        cC.forEach((block, index)=>{
            if(index !== idx){
                nC.push(block)
            }
        })
        setContent(nC)

    }
    
    // function parseHTML(html) {
    //     var t = document.createElement('template');
    //     t.innerHTML = html;
    //     return t.content;
    // }
    return (
        <div className='editor--page'>
            <div className='editor--content'>
                <div className='editor--helpers'>
                        <div className='editor--name'>{name}</div>
                        <div className='editor-top-helpers'>
                            <button onClick={saveContent} className='editor--btn'>Сохранить</button> 
                            {/* <button onClick={mutateBold} className='editor--btn'>Жирный</button>  */}
                            {/* <button onClick={saveContent} className='editor--btn'>Италик</button>  */}
                        </div>
                </div>
                <div className='new--blocks'>
                    
                    <button onClick={addTextBlock}>Текст</button>
                    {/* <button onClick={addHeaderBlock}>Заголовок</button> */}
                    <button onClick={addImageBlock}>Изображение</button>
                </div>
                
                <div className='editable-content'>
                    
                { content.map( (contentItem, idx) => {

                    if( contentItem.type === 'text' ) { 
                        return <div className='mt'><button onClick={moveUp} className='editor-helper-btn' data-idx={idx}>Вверх</button><button className='editor-helper-btn' onClick={moveDown} data-idx={idx}>Вниз</button><button className='editor-helper-btn' onClick={deleteBlock} data-idx={idx}>Удалить</button><div suppressContentEditableWarning={true} contentEditable={true} key={Math.random()}   data-type='text' data-idx={idx} className='text--block--input contentEditable' >{contentItem.value}</div></div>
                    }

                    if( contentItem.type === 'header' ) { 
                        return <div className='mt'><button onClick={moveUp} className='editor-helper-btn' data-idx={idx}>Вверх</button><button className='editor-helper-btn' onClick={moveDown} data-idx={idx}>Вниз</button><button className='editor-helper-btn' onClick={deleteBlock} data-idx={idx}>Удалить</button><div suppressContentEditableWarning={true} contentEditable={true} key={Math.random()}   data-type='text' data-idx={idx} className='text--header--input contentEditable' >{contentItem.value}</div></div>
                    }

                    if( contentItem.type === 'image' ) {
                        if( contentItem.value.length > 0 && contentItem.value ){
                            return (<div className='mt'>
                                <button onClick={moveUp} className='editor-helper-btn' data-idx={idx}>Вверх</button>
                                <button className='editor-helper-btn' onClick={moveDown} data-idx={idx}>Вниз</button>
                                <button className='editor-helper-btn' onClick={deleteBlock} data-idx={idx}>Удалить</button>
                                <img alt={idx} width="100%" value={contentItem.value} src={''+contentItem.value} suppressContentEditableWarning={true} contentEditable={true} key={Math.random()} data-idx={idx} className='image--block contentEditable' />
                                </div>)
                        } else {
                            return (<div className='mt'>
                                <button onClick={moveUp} className='editor-helper-btn' data-idx={idx}>Вверх</button>
                                <button className='editor-helper-btn' onClick={moveDown} data-idx={idx}>Вниз</button>
                                <button className='editor-helper-btn' onClick={deleteBlock} data-idx={idx}>Удалить</button>
                                <br/>Изображение<br/>

                                <input type='file' suppressContentEditableWarning={true} onChange={uploadImage} contentEditable={true} key={Math.random()} data-type='image' data-idx={idx} className=' image--block--input contentEditable' />
                                </div>)
                        }
                        
                    }

                    return null


                } )
                }
                </div>
            </div>
                
           
        </div>
        // <div className='editor--page'>
        //     <div className='editor--container'>
        //         <div className='editor--helpers'>
        //             <div className='editor--name'>Название</div> <span onClick={saveContent} className='pseudo--btn'>[ Сохранить ]</span> [ кнопка ]
        //         </div>
        //         {/* <div className='editor--content--handler'>
                    // { content.map( (contentItem, idx) => {

                    //     if( contentItem.type === 'text' ) { 
                    //         return <div className='theory-flex'><div contentEditable={true} key={Math.random()}   data-type='text' data-idx={idx} className='text--block--input contentEditable' onChange={ handleParagraph } >{contentItem.value}</div></div>
                    //     }

                    //     if( contentItem.type === 'header' ) { 
                    //         return <h4>contentItem.value</h4>
                    //     }

                    //     if( contentItem.type === 'quote' ) { 
                    //         return <mark>contentItem.value</mark>
                    //     }

                    //     if( contentItem.type === 'image' ) { 
                    //         return <img alt={Math.random()} src={contentItem.value}></img>
                    //     }
                        

                    //     return null

                        
                    // } )}
        //         </div> */}
                // <div className='new--blocks'>
                    
                //     <button onClick={addTextBlock}>Текст</button>
                //     <button onClick={addHeaderBlock}>Заголовок</button>
                //     <button onClick={addImageBlock}>Изображение</button>
                // </div>
        //     </div>
        // </div>
    )
}
