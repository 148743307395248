import React, { useState, useEffect} from 'react'
import Navigation from '../Components/Navigation'
import PageTopLabel from '../Components/PageTopLabel'
import TableHead from '../Components/TableHead'
import TableContentPage from '../Components/TableContentPage'
import '../Appp.css'
import DashbEditOverlay from '../Components/Overlays/DashbEditOverlay'
const axios = require('axios')

export default function Dashb() {
    
    const [showOverlay, setOverlay] = useState(false)
    const [showEditOverlay, setEditOverlay] = useState(false)
    const [selectedBlockId, setSelectedBlockId] = useState('')
    const [blockName, setBlockName] = useState('')
    const [blockList, setBlockList] = useState([])

    useEffect(()=>{

        let token = localStorage.getItem('token')
        if(token === undefined || token === null ){
            window.location='/login'
        }else{
            loadBlocks()
        }

    },[])

    const submitOverlay = async (event) =>{
        event.preventDefault();
        let data = {}
        data.name = blockName
        console.log(data)
    
        let answer = await axios.post('/api/blocks', data)
        console.log(answer)
    
        if (answer.data.ok === true){
            setOverlay(false)
            alert('Блок добавлен')
            setBlockName('')
            loadBlocks()
        }
    }

    const loadBlocks = async () =>{
        let answer = await axios.get('/api/blocks')
        console.log(answer)
        let list =  answer.data.list
        setBlockList(list)
    }

    const openOverlay = () =>{
        setOverlay(true)
    }
    const closeOverlay = () =>{
        setOverlay(false)

    }

    const openBlock = (event) => {
        event.preventDefault();
        if(event.target.className.includes('pagebutton')){
            return
        }
        if(event.target.className.includes('icon')){
            return
        }
        let dataset = event.currentTarget.dataset
        let {blockid} = dataset
        
        window.location='/blocks/'+blockid
    }

    const openEditBlock = (event) => {
        let dataset = event.currentTarget.dataset
        let {blockid} = dataset
        setSelectedBlockId(blockid)
        setEditOverlay(true)
    }

    const closeEditBlock = () => {
        setSelectedBlockId('')
        setEditOverlay(false)
    }

    const orderUp = async (event) => {
        let id = event.currentTarget.dataset.blockid
        await axios.post('/api/blocks/up', { id: id })
        loadBlocks()
    }
    const orderDown = async (event) => {
        let id = event.currentTarget.dataset.blockid
        await axios.post('/api/blocks/down', { id: id })
        loadBlocks()
    }
    return (
        <div className='dashboard_container'>
            { showEditOverlay ? <DashbEditOverlay selectedId={selectedBlockId} closeOverlay={closeEditBlock} reload={loadBlocks}/> : <></>}
            {showOverlay ? 
            <div className='overlay'>
                <div className='overlay_content'>
                    <form onSubmit={submitOverlay}>
                        <input className='auth_form' required type='text' value={blockName} placeholder='Название' onChange={e => setBlockName(e.target.value)}></input>
                        <button className='overlay_button' type="submit">Добавить</button>
                    </form>
                    <button className='overlay_button' onClick={closeOverlay}>Закрыть</button>
                </div>
            </div> : 
            <></>}
            
            <Navigation/>
            <div className='dashboard_content'>
            <PageTopLabel text={'Содержание'} action_name={'Добавить'} action={openOverlay}/>
            <TableHead list={['Порядок','Название','Цвет','Ред.']}/>
            <TableContentPage list={blockList} orderUp={orderUp} orderDown={orderDown} openBlock={openBlock} openEditBlock={openEditBlock}/>
            </div>
        </div>
    )
}
