import React from 'react'
import pageUp from './Images/pageUp.png'
import pageDown from './Images/pageDown.png'
import edit from './Images/edit.png'

export default function TableContentPage({ list, openBlock, openEditBlock, orderUp, orderDown }) {
    
    return (
        <div>
            { list.map(table_item=>{
                console.log(table_item.color)
                return (<div className='table_row_a' data-blockid={table_item.id} onClick={openBlock}><div className='table_row'>
                            <div className='arrows' data-blockid={table_item.id}>
                                <button data-blockid={table_item.id} className='pagebutton' onClick={orderUp}><img className='icon' src={pageUp} alt='pageup'/></button>
                                <button data-blockid={table_item.id} className='pagebutton' onClick={orderDown}><img className='icon' src={pageDown} alt='pagedown' /></button>
                            </div>
                            <div className='table_name' data-blockid={table_item.id}>{table_item.name}</div>
                            <div data-blockid={table_item.id}  className='color_block' style={{backgroundColor: table_item.color}}></div>
                            <button  className='pagebutton' data-blockid={table_item.id} onClick={openEditBlock}><img className='icon' data-blockid={table_item.id} src={edit} alt='edit' /> </button>
                        </div></div>) 
            })}
        </div>
    )
}
