import React from 'react'
import pageUp from './Images/pageUp.png'
import pageDown from './Images/pageDown.png'
import edit from './Images/edit.png'

export default function TableContentPage({ list, openEditBlock, openBlock, orderUp, orderDown }) {
    console.log(list)
   
    return (
        <div>
            { list.map(table_item=>{
                return (<div className='table_row_a' data-blockid={table_item.block_id} data-id={table_item.id} onClick={openBlock} href={'/blocks/'+table_item.block_id +'/'+table_item.id}><div className='table_row'>
                            <div className='arrows'>
                                <button className='pagebutton' onClick={orderUp} data-id={table_item.id}><img className='icon' src={pageUp} alt='pageup'/></button>
                                <button className='pagebutton' onClick={orderDown} data-id={table_item.id}><img className='icon' src={pageDown} alt='pagedown' /></button>
                            </div>
                            <div>{table_item.name}</div>
                            
                            <div style={{}} className='color_block'>{ table_item.media_url.length > 0 ? <img alt={table_item.name} className='block-icon' src={`${table_item.media_url}`}/> : <>Иконка не найдена</>}</div>
                            <button data-id={table_item.id} onClick={openEditBlock} className='pagebutton'><img onClick={openEditBlock}  data-id={table_item.id} className='icon' src={edit} alt='edit' /> </button>
                        </div></div>) 
            })}
        </div>
    )
}
