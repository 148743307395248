import React from 'react'

export default function PageTopLabel({ text, action_name, action}) {
    return (
        <div className='page_label_container'>
            <div className='page_label'>{text}</div>
            <button onClick={action} className='page_action'>{action_name}</button>
        </div>
    )
}
