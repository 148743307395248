import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const axios = require('axios')

export default function Task() {

    // const [question, setQuestion ] = useState('')
    const [id, setId ] = useState('')
    const [taskType, setTaskType] = useState('Загрузка')
    const [taskName, setTaskName] = useState('')
    const [taskMediaURL, setTaskMediaURL] = useState('')

    const [ isRegisterValid, setRegisterValid ] = useState(false)


    const [taskQuestion, setTaskQuestion] = useState('')

    const [taskBefore, setTaskBefore] = useState('')
    const [taskAfter, setTaskAfter] = useState('')
    const [taskAnswer, setTaskAnswer] = useState('')
    const [taskAnswer2, setTaskAnswer2] = useState('')


    const [gridP, setGridP] = useState([])
    const [gridG, setGridG] = useState([]);
    const [gridF, setGridF] = useState([])

    // const [answers, setAnswers] = useState([])

    // const [bid, setBid ] = useState('')

    const params = useParams()

    useEffect(()=>{
        let test_id = params.id
        setId(test_id)
        loadTest()
// eslint-disable-next-line
    },[])

    const loadTest = async () => {
        let data = { id: params.id }
        let req = await axios.post('/api/tasks/getOne', data)
        console.log(req)

        let { task } = req.data
        setTaskName(task.name)
        setTaskType(task.type)
        if(task.media_url.length > 0){

            setTaskMediaURL(''+task.media_url)
        }

        const type = task.type
        const json = task.data

        if(type === 0){
            setTaskBefore(json.before)
            setTaskAfter(json.after)
            setTaskAnswer(json.answer)
            setRegisterValid(json.register)
        }

        if(type === 1){
            setTaskQuestion(json.question)
            setTaskAnswer(json.answer)

            setRegisterValid(json.register)
        }
        if(type === 2){
            setTaskQuestion(json.question)
            setTaskAnswer(json.answer)

            setRegisterValid(json.register)
            setTaskAnswer2(json.answer2)
        }
        if(type===3){
            setGridP(json.P)
            setGridF(json.F)
            setGridG(json.G)
        }
    }

    const saveTask = async (event) => {
        event.preventDefault();
        let json = {}

        if(taskType === 0){
            json.before = taskBefore
            json.after = taskAfter
            json.answer = taskAnswer
            json.register = isRegisterValid
        }

        if(taskType === 1){
            json.question = taskQuestion
            json.answer = taskAnswer

            json.register = isRegisterValid
        }
        if(taskType === 2){
            json.question = taskQuestion
            json.answer = taskAnswer

            json.register = isRegisterValid
            json.answer2 = taskAnswer2
        }
        if(taskType === 3){
            json.P = gridP
            json.G = gridG
            json.F = gridF
        }
        

        let data = {}

        data.name = taskName
        data.id = id
        data.type = taskType
        data.json = json

        await axios.post('/api/tasks/update', data);
        alert('OK');
    }
    const moveBack = ( event ) => {
        event.preventDefault()
        let url = sessionStorage.getItem('lastPage')
        if(url === undefined || url === null){
            url = '/'
        }
        window.location = url
    }
    const handleMediaUpdate = async (event)=>{

            let bg = event.target.files[0]
            if(bg === null || bg === undefined){
                return alert('Ошибка')
            }
    
            let formData = new FormData()
            formData.append('file', bg);
            
            const req_config = {
                headers: {
                  "content-type": "multipart/form-data"
                }
              };
            let sendFile = await axios.post('/api/media/', formData, req_config)
            console.log(sendFile)
            if(!sendFile.data.ok){

                alert('Ошибка загрузки' + sendFile.data.e)
                return
            }
            
            let { url } = sendFile.data
    
    
            let updateData = {
                id: id,
                url: url
            }
            let updateBG = await axios.post('/api/tasks/updateBackground', updateData)
    
            if(!updateBG.data.ok){
                return alert('Файл был загружен, но произошла ошибка обновления контента')
            }
    
            if(updateBG.data.ok){
                loadTest();
                return alert('Файл успешно загружен')
            }
    }

    const changeRegister = () => {
        let value = isRegisterValid
        setRegisterValid(!value);
    }

    const addP = () =>{
        let row = gridP
        if(row.length > 1){
            return alert('Нельзя больше 2')
        }
        row.push({value:'', isAnswer: false})
        setGridP([...row])
    }

    const addG = () => {
        let row = gridG
        if(row.length > 7){
            return alert('Нельзя больше 8')
        }
        row.push({value:'', isAnswer: false});
        setGridG([...row])
    }

    const addF = () => {
        let row = gridF
        if(row.length > 15){
            return alert('Нельзя больше 16')
        }
        row.push({value:'', isAnswer: false});
        setGridF([...row])
    }

    const handleGInput =( event ) =>{

        const index = event.target.dataset.idx;
        const value = event.target.value;

        let G = gridG;
        G[index].value = value;
        setGridG([...G])
    }

    const handlePInput = (event)=>{

        const index = event.target.dataset.idx;
        const value = event.target.value;

        let G = gridP;
        G[index].value = value;
        setGridP([...G])

    }
    const handleFInput = (event)=>{

        const index = event.target.dataset.idx;
        const value = event.target.value;

        let F = gridF;
        F[index].value = value;
        setGridF([...F])

    }

    const makePDefault =(event) => {
        const index = event.target.dataset.idx;

        let G = gridP;
        G[index].isAnswer = false;
        setGridP([...G])
    }

    const makePAnswer =(event) => {
        const index = event.target.dataset.idx;

        let G = gridP;
        G[index].isAnswer = true;
        setGridP([...G])
    }

    const makeGDefault =(event) => {

        const index = event.target.dataset.idx;

        let G = gridG;
        G[index].isAnswer = false;
        setGridG([...G])
        
    }

    const makeGAnswer =(event) => {
        
        const index = event.target.dataset.idx;

        let G = gridG;
        G[index].isAnswer = true;
        setGridG([...G])
    }

    const makeFDefault =(event) => {
        
        const index = event.target.dataset.idx;

        let G = gridF;
        G[index].isAnswer = false;
        setGridF([...G])
    }

    const makeFAnswer =(event) => {
        
        const index = event.target.dataset.idx;

        let G = gridF;
        G[index].isAnswer = true;
        setGridF([...G])
    }

    const deleteP = (event) => {
        const index = event.target.dataset.idx;
        let g = gridP
        g.splice(index, 1);
        setGridP([...g])
    }

    const deleteG = (event) => {
        const index = event.target.dataset.idx;
        let g = gridG
        g.splice(index, 1);
        setGridG([...g])
    }

    const deleteF = (event) => {
        const index = event.target.dataset.idx;
        let g = gridF
        g.splice(index, 1);
        setGridF([...g])
    }

    return (
        <div className='test-container'>
            <div className='task-content'>
                <button onClick={moveBack}>Назад</button>
                <h4>Редактор задачи ( {id} ) </h4><button className='test-btn' onClick={saveTask}>Сохранить</button>

                <div className='mt'>Название</div>
                <input className='test-input' value={taskName} onChange={e=>setTaskName(e.target.value)}></input>


                <div className='mt'>Фон</div>
               <input className='test-input' type='file' onChange={handleMediaUpdate}/>

               { taskMediaURL.length > 0 ? <img alt='task-background' className='test-image' src={taskMediaURL}/>: <></>}

                <div className='mt'>Тип задачи ({ taskType })</div>
                <select className='test-input' required type='text' value={taskType} onChange={e => setTaskType(parseInt(e.target.value))}>
                                <option value='0'>
                                    Задача с пропущенным словом
                                </option>
                                <option value='1'>
                                    Вопрос - ответ впечатать
                                </option>
                                <option value='2'>
                                    Вопрос -  2 ответа впечатать
                                </option>
                                <option value='3'>
                                    Схема (P/G/F1)
                                </option>
                            </select>
                { 
                    (taskType === 0) ? <>

<div className='mt'>
                        Важен ли регистер?
                        <input type='checkbox' checked={isRegisterValid} onClick={changeRegister}></input>
                    </div>
                    
                    <div className='mt'>
                        Текст до пропущенного слова
                    </div>
                    <input className='test-input' value={taskBefore} onChange={e=>setTaskBefore(e.target.value)}></input>
                    <div className='mt'>
                        Ответ
                    </div>
                    <input className='test-input' value={taskAnswer} onChange={e=>setTaskAnswer(e.target.value)}></input>
                    <div className='mt'>
                        Текст после пропущенного слова
                    </div>
                    <input className='test-input' value={taskAfter} onChange={e=>setTaskAfter(e.target.value)}></input>
                    
                    </> : <></> }
                 {   (taskType === 1) ? <>
                 
<div className='mt'>
                        Важен ли регистер?
                        <input type='checkbox' checked={isRegisterValid} onClick={changeRegister}></input>
                    </div>
                    <div className='mt'>
                        Вопрос
                    </div>
                    <input className='test-input' value={taskQuestion} onChange={e=>setTaskQuestion(e.target.value)}></input>
                    <div className='mt'>
                        Ответ
                    </div>
                    <input className='test-input' value={taskAnswer} onChange={e=>setTaskAnswer(e.target.value)} ></input>
                 
                 </> : <></>}
                  {  (taskType === 2) ? <>
                  
<div className='mt'>
                        Важен ли регистер?
                        <input type='checkbox' checked={isRegisterValid} onClick={changeRegister}></input>
                    </div>
                  
                    <div className='mt'>
                        Вопрос
                    </div>
                    <input className='test-input' value={taskQuestion} onChange={e=>setTaskQuestion(e.target.value)}></input>
                    <div className='mt'>
                        Ответ
                    </div>
                    <input className='test-input' value={taskAnswer} onChange={e=>setTaskAnswer(e.target.value)}></input>
                    <div className='mt'>
                        Ответ 2
                    </div>
                    <input className='test-input' value={taskAnswer2} onChange={e=>setTaskAnswer2(e.target.value)}></input>
                  
                  </> : <></>}

                  {  (taskType === 3) ? <>
                  <div className='mt'>
                      Схема 
                      <span className='muted'>
                          <li>Нажмите плюс для создания новой клетки</li>

                          <li>Для удаления клетки - нажмите кнопку удалить</li>

                          <li>Для создания ответа в схеме - нажмите кнопку сд. ответом <br/> Ответ выделяется зеленым цветом, впишите правильный ответ в схему. </li>
                      </span>
                  </div>
                  <div className='pgf-grid mt'>
                      <div className='pgf-row'>
                          <div>P <button onClick={addP}>+</button></div>
                          <div className='pgf-value'>
                          {gridP.map( (p, index)=>{
                              return <div className='column'><input type='text' className={p.isAnswer ? 'grid-input grid-green' : 'grid-input' } value={p.value} data-idx={index} onChange={e => handlePInput(e)}></input>
                              { p.isAnswer ? <button className='column-btn' data-idx={index} onClick={makePDefault}>дефолт</button> : <button className='column-btn' data-idx={index} onClick={makePAnswer}>сд. ответом</button> }
                              <button data-idx={index} className='column-btn' onClick={deleteP}>Удалить</button></div>
                          })}
                          </div>
                      </div>
                      <div className='pgf-row'>
                          <div>G <button onClick={addG}>+</button></div>
                          <div className='pgf-value'>
                          {gridG.map( (g, index)=>{
                              return <div className='column'><input type='text' className={g.isAnswer ? 'grid-input grid-green' : 'grid-input' }value={g.value} data-idx={index} onChange={e => handleGInput(e)}></input>
                              { g.isAnswer ? <button className='column-btn' data-idx={index} onClick={makeGDefault}>дефолт</button> : <button data-idx={index} className='column-btn' onClick={makeGAnswer}>сд. ответом</button> }
                              <button data-idx={index} className='column-btn' onClick={deleteG}>Удалить</button></div>
                          })}
                          </div>
                      </div>
                      <div className='pgf-row'>
                      <div>F <button onClick={addF}>+</button></div>
                          <div className='pgf-value'>
                          {gridF.map( (g, index)=>{
                              return <div className='column'><input type='text' className={g.isAnswer ? 'grid-input grid-green' : 'grid-input' } value={g.value} data-idx={index} onChange={e => handleFInput(e)}></input>
                              { g.isAnswer ? <button className='column-btn'  data-idx={index} onClick={makeFDefault}>дефолт</button> : <button data-idx={index} className='column-btn' onClick={makeFAnswer}>сд. ответом</button> }
                              <button data-idx={index} className='column-btn' onClick={deleteF}>Удалить</button>
                              </div>
                          })}
                          </div>
                      </div>
                  </div>
                
                </> : <></>}
                    
                
                
            </div>
        </div>
    )
}
