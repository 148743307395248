import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Navigation from '../Components/Navigation'
import PageTopLabel from '../Components/PageTopLabel'
import TableHead from '../Components/TableHead'
import TableBlockPage from '../Components/TableBlockPage'
import BlocksEditOverlay from '../Components/Overlays/BlocksEditOverlay'
import '../Appp.css'

const axios = require('axios')

export default function Blocks() {

    const [blockName, setBlockName] = useState ('')
    const [rowName, setRowName] = useState('')
    const [showOverlay, setOverlay] = useState(false)
    const [blockList, setBlockList] = useState([])

    const [showEditOverlay, setEditOverlay] = useState(false)
    const [selectedBlockId, setSelectedBlockId] = useState('')


    let parametr = useParams()
    console.log(parametr)

    const getId = () =>{
        let id = parametr.id
        return id
    }
    
    useEffect(() => {
       
        loadRows()

        //eslint-disable-next-line
    }, [])


    const loadRows = async(  ) => {
        let data = {}
        data.block_id = getId()

        let answer = await axios.post('/api/rows/list', data);
        console.log(answer)

        let block_name = answer.data.blockName
        setBlockName(block_name)

        let block_row = answer.data.rows
        console.log(block_row)
        setBlockList(block_row)
    }
 

    const submitOverlay = async (event) =>{
        event.preventDefault();
        let data = {}
        data.block_id = getId()
        data.name = rowName
        console.log(data)
    
        let answer = await axios.post('/api/rows', data)
        console.log(answer)
    
        if (answer.data.ok === true){
            setOverlay(false)
            alert('Блок добавлен')
            setRowName('')
            loadRows()
        }
    }

    const openBlock = (event) => {
        event.preventDefault();
        if(event.target.className.includes('pagebutton')){
            return
        }
        if(event.target.className.includes('icon')){
            return
        }
        let dataset = event.currentTarget.dataset
        let { blockid , id} = dataset
        


        window.location='/blocks/' + blockid + '/' + id
    }
    
    const openEditBlock = (event) => {
        let dataset = event.currentTarget.dataset
        let {id} = dataset
        setSelectedBlockId(id)
        setEditOverlay(true)
    }

    const closeEditBlock = () => {
        setSelectedBlockId('')
        setEditOverlay(false)
    }


    const openOverlay = () =>{
        setOverlay(true)
    }
    const closeOverlay = () =>{
        setOverlay(false)

    }
    const orderUp = async (event) => {
        
        let id = event.currentTarget.dataset.id

        await axios.post('/api/rows/up', { id: id })
        loadRows()
    }
    const orderDown = async (event) => {
        let id = event.currentTarget.dataset.id

        await axios.post('/api/rows/down', { id: id })
        loadRows()
    }
    return (
        <div className='dashboard_container'>
            { showEditOverlay ? <BlocksEditOverlay selectedId={selectedBlockId} closeOverlay={closeEditBlock} reload={loadRows}/> : <></>}
            {showOverlay ? 
            <div className='overlay'>
                <div className='overlay_content'>
                    <form onSubmit={submitOverlay}>
                        <input className='auth_form' required type='text' value={rowName} placeholder='Название' onChange={e => setRowName(e.target.value)}></input>
                        <button className='overlay_button' type="submit">Добавить</button>
                    </form>
                    <button className='overlay_button' onClick={closeOverlay}>Закрыть</button>
                </div>
            </div> : 
            <></>}
            <Navigation/>
            <div className='dashboard_content'>
            <PageTopLabel text={'Блоки / ' + blockName} action_name={'Добавить'} action={openOverlay}/>
            <TableHead list={['Порядок','Название','Иконка','Ред.']}/>
            <TableBlockPage orderUp={orderUp} orderDown={orderDown} list={blockList} openBlock={openBlock} openEditBlock={openEditBlock}/>
            </div>
        </div>
    )
}
