import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const axios = require('axios')

export default function Test() {

    const [question, setQuestion ] = useState('')
    const [id, setId ] = useState('')
    const [answers, setAnswers] = useState([])

    const [bid, setBid ] = useState('')

    const params = useParams()

    useEffect(()=>{
        let test_id = params.id
        setId(test_id)
        loadTest()
// eslint-disable-next-line
    },[])

    const loadTest = async () => {
        let data = { id: params.id }
        let req = await axios.post('/api/tests/getOne', data)
        console.log(req)
        let { test } = req.data
        setQuestion(test.question)
        setAnswers(test.answers)
        setBid(req.data.test.block_id)
        console.log(bid)
    }

    const addAnswer = (event) => {
        let cE = document.getElementsByClassName('answer-input')
        console.log(cE)
        let a = [...answers]
        for( let i = 0; i < a.length; i++){
            a[i].value = cE[i].innerText
        }

        event.preventDefault();
        let obj = {}

        obj.isCorrent = false
        obj.value = ''

        setAnswers([obj, ...a])


    }
    const deleteAnswer = (event) => {
        let cE = document.getElementsByClassName('answer-input')
        console.log(cE)
        let a = [...answers]
        for( let i = 0; i < a.length; i++){
            a[i].value = cE[i].innerText
        }
        let array = []
        let idx = event.currentTarget.dataset.idx
        idx = parseInt(idx)

        a.map( ( answer, index)=> {
            if(index !== idx) {
                return array.push(answer)
            }
            return null
        })

        setAnswers(array)
    }
    const makeCorrect = (event)=>{
        event.preventDefault();
        let idx = event.currentTarget.dataset.idx
        idx = parseInt(idx)
        let a = [...answers]
        a.map(b=>{
            return b.isCorrent=false
        })
        a[idx].isCorrent = true
        setAnswers(a)
    }

    const syncTest = async (a) => {
        let data = { id: params.id, question: question, answers: a}
        let req = await axios.post('/api/tests/update', data)
        console.log(req)
    }

    const saveTest = async (event) => {
        event.preventDefault();
        let cE = document.getElementsByClassName('answer-input')
        console.log(cE)
        let a = [...answers]
        for( let i = 0; i < a.length; i++){
            a[i].value = cE[i].innerText
        }
        setAnswers(a)
        syncTest(a)
    }
    const moveBack = ( event ) => {
        event.preventDefault()
        let url = sessionStorage.getItem('lastPage')
        if(url === undefined || url === null){
            url = '/'
        }
        window.location = url

    }

    return (
        <div className='test-container'>
            <div className='test-content'>
                <button onClick={moveBack}>Назад</button>
                <h4>Редактор теста ( {id} ) </h4><button className='test-btn' onClick={saveTest}>Сохранить</button>
                <div className='mt'>Вопрос теста</div>
                <input type='text' className='test-input' value={question} onChange={e => setQuestion(e.target.value)}></input>
                <div className='mt'>Варианты ответов <button className='test-btn' onClick={addAnswer}>Добавить</button></div>
                { answers.map( (answer, idx) => {
                    return (<div className='test-table'>
                        <div contentEditable={true} suppressContentEditableWarning={true} className='answer-input'>{answer.value}</div>
                        <div>
                        {
                            answer.isCorrent ?  <div className='green-test'>Правильный ответ</div> : <button className='test-btn' data-idx={idx} onClick={makeCorrect}>Сделать правильным</button>
                        }
                        </div>
                        <div>
                        <button className='test-btn' data-idx={idx}  onClick={deleteAnswer}>Удалить</button>
                        </div>
                    </div>)
                })}
                
            </div>
        </div>
    )
}
