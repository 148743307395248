import React, { useState } from 'react'
import '../Appp.css'
const axios = require('axios')

export default function Auth() {
    const [email, setEmail] = useState('')
    const [pwd, setPassword] = useState('')

    const submitForm = async(event) => {
        event.preventDefault();
        let form = {} 
        form.email = email 
        form.password = pwd
        console.log(form);

        let answer = await axios.post('/api/auth/login', form)
        console.log(answer) 

        if (answer.data.ok === true){
            localStorage.setItem('token', answer.data.token)
            window.location='/dashboard'
            
        }
        else {
            alert(answer.data.msg)
        }
    }
    return (
        <div className='flex_center'>
            <div className='auth_log'>
                <div className='auth_vhod'>Вход</div>
                <form  onSubmit={submitForm}>
                    <div className='auth_logform'>
                    <input className='auth_form' required type='email' placeholder="Email" onChange={e => setEmail(e.target.value)} id="email" name="email"></input>
                    <input className='auth_form' required type='password' placeholder="Пароль" onChange={e => setPassword(e.target.value)} id="pwd" name="pwd"></input>
                    <button className='button' type="submit">Войти</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
