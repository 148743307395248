import React from "react";
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";
import Auth from './pages/Auth.js';

import Blocks from "./pages/Blocks.js";
import Chapter from "./pages/Chapter.js";
import Dashb from "./pages/Dashb.js";
import BlockId from "./pages/BlockId.js";
import Register from "./pages/Register";
import Test from "./pages/Test.js";
import Default from "./pages/Default.js";
import Theory from "./Components/Subpages/Theory.js";
import Task from "./pages/Task.js";


// 1. BlocksList - Dashboard
// 2. RowsList in one Block -- BlockID
// 3. One Row (Chapters, Tests, Tasks) -- RowID

// 3.1. OneRow->Chapters - ChaptersList
// 3.1.1 One Chapter

// 3.2. OneRow->Tests - TestsList
// 3.2.1 One Test

// 3.3. OneRow->Tasks - TasksList
// 3.3.1 One Task


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Default/>}></Route>

        <Route path='/chapter' exact element={<Chapter/>}></Route>

        <Route path='/blocks/:id' element={<Blocks/>}></Route>
        <Route path='/blocks/:id/:block_id' element={<BlockId/>}></Route>

        <Route path='/theory/:id' element={<Theory/>}></Route>

        <Route path='/test/:id' element={<Test/>}></Route>
        
        <Route path='/task/:id' element={<Task/>}></Route>
        <Route path='/dashboard' exact element={<Dashb/>}></Route>

        <Route path='/login' exact element={<Auth/>}></Route>

        <Route path='/registration' exact element={<Register/>}></Route>

      </Routes>
    </Router>
  );
}

export default App;
